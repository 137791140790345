<template>
  <div class="">
    <div class="relative graybox" style="background-color: #F7FAFC;">
      <svg class="rightpanel" width="314" height="107" viewBox="0 0 314 107" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 107V67.5L314.5 0V40L0 107Z" fill="#10B981" fill-opacity="0.5"/>
      </svg>
      <svg class="leftpanel2" width="159" height="74" viewBox="0 0 159 74" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 73.5V33.5L159 0V40L0 73.5Z" fill="#EF4444"/>
      </svg>
      <svg class="leftpanel" width="315" height="107" viewBox="0 0 315 107" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 107V67.5L314.5 0V40L0 107Z" fill="#10B981" fill-opacity="0.5"/>
      </svg>
      <div class="absolute w-4/5 card-white" style="top:50px; right:0; left: 0; margin: 0 auto">
        <div class="flex justify-between">
          <img src="@/assets/TIZETI.png" alt="logo" class="logo">
          <img src="@/assets/logo.svg" alt="logo" class="logo">
        </div>
        
        <div class="mt-4 rounded bg-white px-5 py-5 lg:p-10" style="box-shadow:0px 5px 15px rgba(0, 0, 0, 0.12), 0px 15px 35px rgba(60, 66, 87, 0.08);">
          <h1 class="text-2xl font-semibold text-green-500" style="">Forgot Password</h1>
          <form style="color:#3C4257" @submit.prevent="submit">
            <!-- <h1>Enter your email</h1> -->
            <div class="mt-4">
              <label class="text-sm">Email</label>
              <div class="border rounded border-solid border-gray-200 mt-2 px-1">
                <input type="text" placeholder="janedoe@placehlder.com" class="p-2 focus:outline-none border-0 w-full">
              </div>
            </div>
            <button style="padding: 13.5px 10px;" class="focus:outline-none w-full bg-green-500 rounded mt-4 text-white text-center">
              Continue
            </button>
          </form>
        </div>
        <!-- <p style="color: #4F566B" class="mt-6">Don't have an account ? <span style="color: #635CFF">Sign up</span></p> -->
        <div class="mt-2 flex gap-2" style="color: #4F566B">
          <p class="text-sm">PaddyCover .</p>
          <p class="text-sm">Contact .</p>
          <p class="text-sm">Privacy & terms</p>
        </div>
      </div>
    </div>
    <div class="white-box bg-white" style="min-height: 50vh"></div>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Home',
  methods:{
    submit(){
      this.$router.push('/app')
    }
  }
}
</script>
<style scoped>
.graybox{
  height: 50vh
}
.logo{
  width: 100px;
  height: 50px;
  /* border: 1px solid red */
}
.rightpanel,.leftpanel,.leftpanel2{
  display: none
}
@media only screen and (min-width: 768px){
  .logo{
    width: 150px;
    height: 70px;
    /* border: 1px solid red */
  }
  .graybox{
    height: 80vh
  }
  .graybox:after{
    content: "";
    position: absolute;
    /* border: 1px solid red; */
    height: 350px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -250px;
    background-color: #fff;
    transform: skewY(-11deg);
    z-index: 10
  }
  .card-white{
    max-width: 500px;
    z-index: 100
  }
  .rightpanel{
    position: absolute;
    right: 0;
    bottom: 150px;
    z-index: 100
  }
  .leftpanel{
    position: absolute;
    left: 0;
    bottom: -60px;
    z-index: 100
  }
  .leftpanel2{
    position: absolute;
    left: 0;
    bottom: -40px;
    z-index: 100
  }
}
</style>
